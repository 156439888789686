/* eslint-disable no-inline-styles/no-inline-styles */
import React from 'react';

import dynamic from 'next/dynamic';

import { componentDebugAtom } from '@/core/features/hidden-menu/component-debugger';
import { useAtomValue } from '@/core/features/store/atom-store';

const PageComponentDebugInfoBox = dynamic(
    () =>
        import(
            /* webpackChunkName: "PageComponentDebugInfoBox" */ '@/features/a-dynamic-page/components/debug/page-component-debug-info'
        ),
);

export default function PageComponentDebugWrapper(props: React.PropsWithChildren<{ type: string }>) {
    const isComponentDebug = useAtomValue(componentDebugAtom);

    if (isComponentDebug) {
        return <PageComponentDebugInfoBox {...props} />;
    }
    return <>{props.children}</>;
}
